.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
}
.name {
  margin: 0;
  text-align: right; /* to break text nicer when screen is smaller than around 340 */
}
.title {
  text-align: right;
}

a {
  color: #010101;
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}
